import { graphql } from 'gatsby';
import React from 'react';
import { StructuredTextDocument } from 'react-datocms/dist/esm';
import Container from '../../../primitives/grid/container';
import StyledButton from '../../../primitives/styled-button';
import PlayButton from '../hero/play-button.svg';
import VideoPopover from '../../../primitives/video-popover';
import { breakpoints } from '../../../primitives/tokens';
import Typography from '../../../primitives/typography';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import Background from '../../../primitives/backgrounds';
import Asset from '../../../primitives/asset';

export type FeatureCircleFlowchartProps = Queries.FeatureCircleFlowchartPropsFragment;

const FeatureCircleFlowchart = ({
    richTitle,
    body,
    image,
    video,

}: FeatureCircleFlowchartProps): JSX.Element => (
    <div css={{
        marginTop: '160px',
        marginBottom: '160px',
        [breakpoints.mb]: {
            marginTop: '78px',
            marginBottom: '64px',
        },
    }}
    >
        <Background breakpoints={{
            dt: {
                backgroundColor: 'primaryOrange',
                gradient: 'orange',
                clip: 'top',
            },
        }}
        >
            <Container
                css={{
                    marginTop: '110px',
                    marginBottom: '160px',
                    paddingBottom: '80px',
                    [breakpoints.mb]: {
                        marginTop: '78px',
                        marginBottom: '64px',
                        paddingBottom: '40px',
                    },
                }}
            >
                <Typography
                    fontSize={{
                        dt: 4053,
                        tb: 3234,
                        mb: 2835,
                    }}
                    override
                    css={{
                        textAlign: 'center',
                        margin: 'auto',
                        maxWidth: '930px',
                        marginBottom: '20px',
                        [breakpoints.mb]: {
                            maxWidth: '70%',
                            marginBottom: '20px',
                        },
                    }}
                    fontWeight="medium"
                    color="shadesWhite"
                >
                    <CustomStructuredTextRenderer data={richTitle as unknown as StructuredTextDocument} />
                </Typography>
                <Row>
                    <Col breakpoints={{
                        dt: { span: 10, start: 1 },
                        tb: { span: 12, start: 0 },
                        mb: { span: 4, start: 0 },
                    }}
                    >
                        <Row>
                            <Col breakpoints={{
                                dt: { span: 6 },
                            }}
                            >
                                <div css={{
                                    margin: 'auto',
                                    width: '100%',
                                }}
                                >
                                    <Typography
                                        fontSize={{
                                            dt: 2429,
                                            tb: 1822,
                                            mb: 1627,
                                        }}
                                        color="shadesWhite"
                                        fontWeight="light"
                                        css={{
                                            maxWidth: '600px',
                                            marginBottom: '84px',
                                            marginTop: '84px',
                                            [breakpoints.mb]: {
                                                textAlign: 'center',
                                                marginTop: '58px',
                                                marginBottom: '58px',
                                            },
                                            p: {
                                                marginBottom: '32px',
                                                [breakpoints.tb]: {
                                                    marginBottom: '18px',
                                                },
                                                [breakpoints.mb]: {
                                                    marginBottom: '12px',
                                                },
                                            },
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: body,
                                        }}
                                    />
                                    <VideoPopover video={video[0].video} link={video[0].link}>
                                        <StyledButton
                                            variant="darkSecondary"
                                            css={{
                                                minWidth: '148px',
                                            }}
                                        >
                                            <PlayButton />
                                            <span>{video[0].title}</span>
                                        </StyledButton>
                                    </VideoPopover>
                                </div>
                            </Col>
                            <Col breakpoints={{
                                dt: { span: 6 },
                            }}
                            >
                                <Asset
                                    css={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    asset={image}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Background>
    </div>
);

export default FeatureCircleFlowchart;

export const query = graphql`
    fragment FeatureCircleFlowchartProps on DatoCmsFeatureCircleFlowchart {
        __typename
        richTitle {
            blocks
            links
            value
        }
        body
        image {
            ...DatoCmsInternalAsset
        }
        video {
            ...VideoPopoverButtonProps
        }
    }
`;
